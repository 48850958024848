import React from "react";
import styles from "./crop-image.module.scss";

const CropImage = ({ width, height, source }) => {
  return (
    <div className={styles.cropImage} style={{ width, height, backgroundImage: `url(${source})`}}>
    </div>
  );
};

export default CropImage;
