import React from "react";
// import { useBreakpoint } from "gatsby-plugin-breakpoints";
import CropImage from "@atoms/CropImage";

const GroupImg = ({ source, top, left, right }) => {
  // const breakpoints = useBreakpoint();
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: top[0],
          left: left[0],
          right: right[0],
          // display: breakpoints.sm ? "none" : "block",
        }}
      >
        <CropImage width={506} height={283} source={source[0]} />
      </div>
      <div
        style={{
          position: "absolute",
          top: top[1],
          left: left[1],
          right: right[1],
          zIndex: -1,
          // display: breakpoints.sm ? "none" : "block",
        }}
      >
        <CropImage width={327} height={183} source={source[4]} />
      </div>
      <div
        style={{
          position: "absolute",
          top: top[2],
          left: left[2],
          right: right[2],
          // display: breakpoints.sm ? "none" : "block",
        }}
      >
        <CropImage width={485} height={271} source={source[2]} />
      </div>
      <div
        style={{
          position: "absolute",
          top: top[3],
          left: left[3],
          right: right[3],
          // display: breakpoints.sm ? "none" : "block",
        }}
      >
        <CropImage width={340} height={190} source={source[3]} />
      </div>
      <div
        style={{
          position: "absolute",
          top: top[4],
          left: left[4],
          right: right[4],
          overflow: "hidden",
          width: 250,
          zIndex: -1,
          // display: breakpoints.sm ? "none" : "block",
        }}
      >
        <CropImage width={292} height={163} source={source[1]} />
      </div>
    </div>
  );
};

export default GroupImg;
