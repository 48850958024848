import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import styles from "./our-offices.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import GroupImg from "@molecules/GroupImg";
import group1_1 from "../../../assets/image/group1_1.webp";
import group1_2 from "../../../assets/image/group1_2.webp";
import group1_3 from "../../../assets/image/group1_3.webp";
import group1_4 from "../../../assets/image/group1_4.webp";
import group1_5 from "../../../assets/image/group1_5.webp";

import group2_1 from "../../../assets/image/group2_1.webp";
import group2_2 from "../../../assets/image/group2_2.webp";
import group2_3 from "../../../assets/image/group2_3.webp";
import group2_4 from "../../../assets/image/group2_4.webp";
import group2_5 from "../../../assets/image/group2_5.webp";

import group3_1 from "../../../assets/image/group3_1.webp";
import group3_2 from "../../../assets/image/group3_2.webp";
import group3_3 from "../../../assets/image/group3_3.webp";
import group3_4 from "../../../assets/image/group3_4.webp";
import group3_5 from "../../../assets/image/group3_5.webp";

import group4_1 from "../../../assets/image/group4_1.webp";
import group4_2 from "../../../assets/image/group4_2.webp";
import group4_3 from "../../../assets/image/group4_3.webp";
import group4_4 from "../../../assets/image/group4_4.webp";
import group4_5 from "../../../assets/image/group4_5.webp";

import group5_1 from "../../../assets/image/group5_1.webp";
import group5_2 from "../../../assets/image/group5_2.webp";
import group5_3 from "../../../assets/image/group5_3.webp";
import group5_4 from "../../../assets/image/group5_4.webp";
import group5_5 from "../../../assets/image/group5_5.webp";

const groupImages = [
  <GroupImg
    source={[group1_1, group1_2, group1_3, group1_4, group1_5]}
    top={[200, 600, "75%", 160, "unset"]}
    left={[-100, 50, "50%", "unset", "unset"]}
    right={["unset", "unset", "unset", 100, 0]}
  />,
  <GroupImg
    source={[group2_2, group2_5, group2_3, group2_4, group2_1]}
    top={[200, 600, "75%", 160, "unset"]}
    left={[-100, 50, "50%", "unset", "unset"]}
    right={["unset", "unset", "unset", 100, 0]}
  />,
  <GroupImg
    source={[group3_1, group3_2, group3_3, group3_4, group3_5]}
    top={[200, 600, "75%", 160, "unset"]}
    left={[-100, 50, "50%", "unset", "unset"]}
    right={["unset", "unset", "unset", 100, 0]}
  />,
  <GroupImg
    source={[group4_1, group4_2, group4_3, group4_4, group4_5]}
    top={[200, 600, "75%", 160, "unset"]}
    left={[-100, 50, "50%", "unset", "unset"]}
    right={["unset", "unset", "unset", 100, 0]}
  />,
  <GroupImg
    source={[group5_1, group5_2, group5_3, group5_4, group5_5]}
    top={[200, 600, "75%", 160, "unset"]}
    left={[-100, 50, "50%", "unset", "unset"]}
    right={["unset", "unset", "unset", 100, 0]}
  />,
];

const groupImagesMobile = [
  <GroupImg
    source={[group1_1, group1_2, group1_3, group1_4, group1_5]}
    top={[332, 200, 305, 420, 170]}
    left={[-244, -80, 144, "unset", "unset"]}
    right={["unset", "unset", 100, 100, -28]}
  />,
  <GroupImg
    source={[group2_2, group2_5, group2_3, group2_4, group2_1]}
    top={[332, 200, 305, 420, 170]}
    left={[-244, -80, 144, "unset", "unset"]}
    right={["unset", "unset", 100, 100, -28]}
  />,
  <GroupImg
    source={[group3_1, group3_2, group3_3, group3_4, group3_5]}
    top={[332, 200, 305, 420, 170]}
    left={[-244, -80, 144, "unset", "unset"]}
    right={["unset", "unset", 100, 100, -28]}
  />,
  <GroupImg
    source={[group4_1, group4_2, group4_3, group4_4, group4_5]}
    top={[332, 200, 305, 420, 170]}
    left={[-244, -80, 144, "unset", "unset"]}
    right={["unset", "unset", 100, 100, -28]}
  />,
  <GroupImg
    source={[group5_1, group5_2, group5_3, group5_4, group5_5]}
    top={[332, 200, 305, 420, 170]}
    left={[-244, -80, 144, "unset", "unset"]}
    right={["unset", "unset", 100, 100, -28]}
  />,
];

const OurOffices = () => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  const [componentIndex, setComponentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    let timeout;
    const interval = setInterval(() => {
      setIsVisible(false);
      timeout = setTimeout(() => {
        setComponentIndex(prev => prev + 1);
      }, 1000);
    }, 5000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setIsVisible(true);
  }, [componentIndex]);

  return (
    <section className={styles.section} id='offices' >
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            exit={{ opacity: 0 }}
          >
            {breakpoints.sm ? 
            groupImagesMobile[componentIndex % groupImagesMobile.length] : 
            groupImages[componentIndex % groupImages.length]}
          </motion.div>
        )}
      </AnimatePresence>
      <h2 className={styles.section_title}>{t("WORK.OFFICES")}</h2>
    </section>
  );
};

export default OurOffices;
